import React from 'react';

const ServiceCard = ({ image, title, description, imagePosition }) => {
  return (
    <div className={`service-card ${imagePosition}`}>
      <img src={image} alt={title} className="service-image" />
      <div className="service-text">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default ServiceCard;

import React, { useEffect } from 'react';
import Header from './Header';
import HeroSection from './HeroSection';
import ServiceCard from './ServiceCard'; 
import ciscoCatalystCenterImage from '../images/ciscoCatalystCenterImage.jpg';
import ciscoSpacesImage from '../images/ciscoSpacesImage.jpg'
import proactiveMonitoringImage from '../images/proactiveMonitoringImage.jpg'
import wifiSiteSurveysImage from '../images/wifiSiteSurveysImage.jpg'
import wifiOptimizationImage from '../images/wifiOptimizationImage.jpg'
import webDevelopment from '../images/webDevelopment.jpg'

const MainPage = () => {
  useEffect(() => {
    // This will set the tab name to "Home - WiFi Rescue LLC"
    document.title = 'Home - WiFi Rescue LLC';
  }, []);
  return (
    <>
      <div className="services-container">
      <ServiceCard
          image= {wifiSiteSurveysImage}  // Replace with your actual image path
          title="WiFi Site Surveys"
          description="Our WiFi Site Surveys deliver detailed insights into your network's coverage, helping to optimize performance across all areas."
          imagePosition="left" 
        />
        <ServiceCard
          image= {webDevelopment} 
          title="Web Development"
          description="Innovate your online presence with our comprehensive web development services, crafting bespoke websites that drive engagement and growth."
          imagePosition="left" 
        />
        <ServiceCard
          image= {ciscoCatalystCenterImage} 
          title="Cisco Catalyst Center"
          description="Unlock the potential of your network with our top-tier Cisco Catalyst Center services, ensuring high performance and reliability."
          imagePosition="left" 
        />
        <ServiceCard
          image= {ciscoSpacesImage} // Replace with your actual image path
          title="Cisco Spaces"
          description="Leverage the power of Cisco Spaces for your business, providing you with smart, secure, and scalable networking solutions."
          imagePosition="left" 
        />
        <ServiceCard
          image= {proactiveMonitoringImage} 
          title="Proactive Monitoring"
          description="Stay ahead of potential issues with our Proactive Monitoring services, maintaining the health and security of your IT infrastructure."
          imagePosition="left" 
        />
        <ServiceCard
          image= {wifiOptimizationImage}  // Replace with your actual image path
          title="WiFi Optimization"
          description="Enhance and manage your WiFi infrastructure for superior performance, reliability, and user experience with our comprehensive WiFi optimization services."
          imagePosition="left" 
        />
      </div>
    </>
  );
};

export default MainPage;

import React, { useEffect } from 'react';
import '../css/ContactUs.css'; // Ensure the path to your CSS file is correct

const ContactUs = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    const name = form.name.value;
    const email = form.email.value;
    const phone = form.phone.value;
    const message = form.message.value;

    const emailBody = `Name: ${name}%0D%0AEmail: ${email}%0D%0APhone: ${phone}%0D%0AMessage: ${message}`;
    const mailtoLink = `mailto:eric@wifidrs.com?subject=Contact from ${name}&body=${emailBody}`;

    window.location.href = mailtoLink;
  };

  useEffect(() => {
    // This will set the tab name to "Contact Us - WiFi Rescue LLC"
    document.title = 'Contact Us - WiFi Rescue LLC';
  }, []);

  return (
    <div className="contact-us-container">
      <div className="contact-info-page">
  <h2>Contact With Us Today</h2>
  <p>CONTACT US DIRECTLY</p>
  <div className="contact-details">
    <p><strong>PHONE NUMBER</strong><br/>252-258-6678</p>
    <p><strong>EMAIL</strong><br/><a href="mailto:eric@wifidrs.com">eric@wifidrs.com</a></p>
  </div>
</div>

      <div className="contact-form">
        <form onSubmit={handleSubmit}>
          <input type="text" name="name" placeholder="Enter Full Name" />
          <input type="email" name="email" placeholder="Enter Email" />
          <input type="tel" name="phone" placeholder="Enter Phone Number" />
          <textarea name="message" placeholder="What Services Are You Interested In?"></textarea>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;

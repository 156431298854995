import React from 'react';
import { Link } from 'react-router-dom'; // Import the Link component

const Header = () => {
  return (
    <header className="header">
      <Link to="/"> {/* Set the path to your MainPage.js route */}
        <h1>WiFi Rescue LLC</h1>
      </Link>
    </header>
  );
};

export default Header;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import ContactUs from './components/ContactUs';
import './App.css';
import MainPage from './components/MainPage';

function App() {
  return (
    <Router>
        <Header /> {/* This will always be displayed */}
      <Routes> {/* 'Routes' replaces 'Switch' in v6 */}
        <Route path="/" element={<MainPage />} /> {/* 'element' replaces 'component' */}
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
      <Footer /> {/* This will always be displayed */}
    </Router>
  );
}

export default App;

import React from 'react';
import { Link } from 'react-router-dom'; // Import the Link component

const Footer = () => {
  return (
    <footer className="footer">
      <div className="contact-info">
        <Link to="/contact-us"><p>Contact Us</p></Link>
        <p>Email: Erick@wifidrs.com</p>
        <p>Phone: 252-258-6678</p>
      </div>
      <div className="social-media-links">
        <a href="https://www.linkedin.com/in/erickfmartin/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
        <a href="https://www.instagram.com/wifidoctors/" target="_blank" rel="noopener noreferrer">Instagram</a>
        <a href="https://www.youtube.com/channel/UCKyvCsi6arRgK-VFcpXCYfA" target="_blank" rel="noopener noreferrer">Youtube</a>
      </div>
    </footer>
  );
};

export default Footer;
